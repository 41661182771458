import { isMobile } from '../utils/device';
import { handleDrEditionVideo } from './dr-edition-video';
import { trackTeaser } from '../front-tracking.js';
import Lazyload from '../utils/Lazyload';
import { getConsent } from '@vgno/utils';
import { getEnvironmentId } from '../tracking/pulse';
import { getUnleashClient } from '../utils/unleash';

const BASE_URL = 'https://dr-edition-server.inventory.schibsted.io';

const excludedItems = [];

/**
 * @param {string} url
 * @param {string} as
 */
function preload(url, as) {
    const link = document.createElement('link');
    link.rel = 'preload';
    link.href = url;
    link.as = as;
    document.head.appendChild(link);
}

function getPathnameFromParsedUrl(url) {
    try {
        const website = new URL(url);

        return website.pathname;
    } catch {
        console.log('Error parsing redirect URL', url);

        return '';
    }
}

function appendTrackingDataScript(pulseData, editionName, container) {
    const title = document.createElement('div');
    title.innerHTML = pulseData.title;

    const trackingDataScript = document.createElement('script');
    trackingDataScript.type = 'application/json';
    trackingDataScript.textContent = JSON.stringify({
        articleId: getPathnameFromParsedUrl(pulseData.url),
        // For legacy tracking
        drFrontId: 'df-' + pulseData.creativeId,
        section: editionName,
        teaserText: title.textContent,

        // Defaults
        skin: 'default',
        size: 1,
        brand: 'vg',
        skinIcon: null,
    });
    trackingDataScript.className = 'tracking-data';
    container.appendChild(trackingDataScript);
}

async function fetchDrEditionItem(
    editionName,
    excludedItems,
    SPiD,
    editionDataUrl,
    envid,
) {
    const { userId } = SPiD;
    const url = `${BASE_URL}/editions?editionName=vg-${editionName}&excludedItems=${JSON.stringify(
        excludedItems,
    )}&spid=${userId}&envid=${envid}&urlData=${editionDataUrl}`;

    const response = await fetch(url);
    const data = await response.json();

    if (data.css) {
        preload(data.css, 'style');
    }

    return data;
}

/**
 * @param {string} htmlContent
 * @returns {string}
 */
function removeTrackingPixel(htmlContent) {
    const pattern = /<img [^>]*class="tracking-pixel"[^>]*>/g;
    return htmlContent.replace(pattern, '');
}

async function renderItem(drEditionContainer, item) {
    const isMobileDevice = isMobile();

    const consentedToAll = (await getConsent('CMP:advertising')) === '1';

    const mobileHTML = consentedToAll
        ? item.mobileHTML
        : removeTrackingPixel(item.mobileHTML);
    const desktopHTML = consentedToAll
        ? item.desktopHTML
        : removeTrackingPixel(item.desktopHTML);

    if (isMobileDevice) {
        drEditionContainer.innerHTML = mobileHTML;
    } else {
        drEditionContainer.innerHTML = desktopHTML;
    }

    trackTeaser(drEditionContainer);
}

function appendDataAttributesForContainer(element, pulseData, placementId) {
    element.dataset.creativeId = pulseData.creativeId;
    element.dataset.itemId = pulseData.itemId;
    element.dataset.editionId = pulseData.editionId;
    element.dataset.algorithm = pulseData.algorithm ?? 'sov';
    element.dataset.clientName = pulseData.clientName;
    element.dataset.placementId = placementId;
}

function getEditionDataFromUrl() {
    const url = window.location.href;
    const urlObj = new URL(url);
    return urlObj.searchParams.get('dre_override_inv_code') || '';
}

/**
 * @param {HTMLDivElement} partnerstudioAdElm
 */
function hideBackfillAd(partnerstudioAdElm) {
    const nextElm = partnerstudioAdElm.nextElementSibling;
    if (
        nextElm &&
        nextElm.matches('.advertory.advertory-vg-netboard-wrapper__front')
    ) {
        nextElm.style.display = 'none';
    }
}

async function fetchAndHandleDrEditionItem(front) {
    try {
        const drEditionArticle = front.querySelector('.articles');
        const drEditionDataContainer = front.querySelector('.container');
        let SPiD = {};
        try {
            SPiD = await window.SPiD_Identity.hasSession();
        } catch {
            // silent catch
        }

        const envid = await getEnvironmentId();

        const editionDataUrl = getEditionDataFromUrl();

        const placementId = front?.id;

        let trackAsVgpersCtrl = false;
        if (placementId === 'partnerstudio-2') {
            const unleash = await getUnleashClient();
            if (unleash.isEnabled('vgpers-affiliate-row18')) {
                if (unleash.isEnabled('vgpers-partnerstudio2-takeover')) {
                    front.classList.add('partnerstudio-empty');
                    hideBackfillAd(front);
                    return;
                } else {
                    trackAsVgpersCtrl = true;
                }
            }
        } else if (placementId === 'partnerstudio-1-5') {
            const unleash = await getUnleashClient();
            if (unleash.isEnabled('vgpers-affiliate-row10')) {
                if (unleash.isEnabled('vgpers-partnerstudio1-takeover')) {
                    front.classList.add('partnerstudio-empty');
                    hideBackfillAd(front);
                    return;
                } else {
                    trackAsVgpersCtrl = true;
                }
            }
        }

        const item = await fetchDrEditionItem(
            placementId,
            excludedItems,
            SPiD,
            editionDataUrl,
            envid,
        );

        if (!item?.itemId) {
            front.classList.add('partnerstudio-empty');
            return;
        }

        excludedItems.push(item.itemId);
        await renderItem(drEditionArticle, item);
        appendDataAttributesForContainer(
            drEditionDataContainer,
            item.pulseData,
            placementId,
        );
        drEditionDataContainer.querySelectorAll('a').forEach((a) => {
            /** @param {HTMLAnchorElement} a */
            const { dataset } = drEditionDataContainer;
            a.search = createDrEditionUrlParameters(
                a,
                trackAsVgpersCtrl,
                dataset,
            );
        });

        if (
            item?.videoConfiguration?.id ||
            item?.videoConfiguration?.preview?.url
        ) {
            handleDrEditionVideo(item.videoConfiguration);
        }

        appendTrackingDataScript(item.pulseData, front?.id, drEditionArticle);
        // For faster tapping in Safari. See: https://www.reddit.com/r/webdev/comments/18zjara/i_frigging_love_ios_safari/
        drEditionDataContainer
            .querySelector('a')
            .addEventListener('pointerup', () => null);
    } catch {
        console.error(new Error('Error fetching dr. edition item'));
    }
}

function createDrEditionUrlParameters(
    anchorElement,
    trackAsVgpersCtrl,
    dataset,
) {
    const { clientName, placementId, creativeId } = dataset;
    const decodedParameters = new URLSearchParams(
        decodeURIComponent(anchorElement.search),
    );
    const params = new URLSearchParams(anchorElement.search);

    const service = anchorElement.href.includes('/annonsorinnhold/')
        ? 'partnerstudio_personalized_front'
        : 'affiliate_personalized_front';

    const parametersMapper = {
        utm_source: 'vgfront',
        utm_content: placementId,
        utm_campaign: clientName,
        utm_medium: creativeId,
        ...(trackAsVgpersCtrl && {
            utm_term: `${service}-control-frontsjef-1`,
        }),
    };

    Object.entries(parametersMapper).forEach(([key, value]) => {
        if (!decodedParameters.has(key)) {
            params.set(key, encodeURIComponent(value));
        }
    });

    return params.toString();
}

export default async () => {
    const drEditionElements = $$('.partnerstudio-front');

    const observer = new Lazyload(drEditionElements, {
        rootMargin: '400% 0px',
    });
    observer.observe(({ target }) => fetchAndHandleDrEditionItem(target));
};
